export const formatDate = (date: string | number | Date, monthOnly?: boolean) => {
  const d = new Date(date);
  let month = `${d.toLocaleString("default", { month: "long" })}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) {
    month = `0${month}`;
  }

  if (day.length < 2) {
    day = `0${day}`;
  }

  return monthOnly ? month : `${month} ${day},` + ` ${year}`;
};
export const formatShortDate = (date: string | number | Date) => {
  const d = new Date(date);
  const month = `${d.toLocaleString("default", { month: "short" })}`;
  const year = d.getFullYear();
  return `${month} ${year}`;
};
