// Get config constants from the env file
// ToDo: On re-tool of build process, consider replacing this and individual files access the .env directly
export default {
  authentication: {
    tenantId: process.env.REACT_APP_AUTH_TENANT_ID || "",
    clientId: process.env.REACT_APP_AUTH_CLIENT_ID || "",
    campusApiScope: process.env.REACT_APP_AUTH_CAMPUS_API_SCOPE || "",
  },
  url: {
    canvas: process.env.REACT_APP_URL_CANVAS || "",
  },
  gtm: {
    id: process.env.REACT_APP_GTM_ID || "",
  },
  hotJar: {
    id: process.env.REACT_APP_HOTJAR_ID || "",
    version: process.env.REACT_APP_HOTJAR_VER || "",
  },
  insights: {
    instrumentationKey: process.env.REACT_APP_INSIGHTS_INSTRUMENTATION_KEY || "",
  },
};
