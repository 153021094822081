import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { logOutOutline } from "ionicons/icons";
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonList,
  IonItem,
  IonPage,
  IonContent,
  IonMenu,
  IonMenuToggle,
  IonLabel,
  IonIcon,
  IonFooter,
  IonButton,
  IonButtons,
} from "@ionic/react";
import cx from "classnames";

import { LocalRoutes } from "constants/routes";
import { NEXFORD_PRIVACY_POLICY, NEXFORD_MAIL_SUCCESS } from "constants/external-routes";
import { useInitialisationContext } from "utils/context/Initialisation";
import { isMobileOS } from "utils/detect-device";
import { signOutClickHandler } from "utils/profile-utils";
import { desktopMenuNavItems, mobileFooterNavItems } from "constants/navigation";

import { NxuAlert } from "@nexford/nexford-ui-component-library";

import LoadingInterstitial from "components/molecule/loading-interstitial";
import Header from "components/molecule/header";
import GetInTouch from "components/atom/get-in-touch/get-in-touch";
import { ReactComponent as LogoImg } from "assets/img/logos/logo-titlebar.svg";

import "./page-wrap.scss";

/**
 * Inner page component to be added to top level page components,
 * setting a standardised page width and padding
 */
export const PageContent = (props: { fullWidth?: boolean; className?: string; children: React.ReactNode }) => {
  const { children, className, fullWidth } = props;

  return <div className={cx("page-content", className, fullWidth ? "page-content__full" : "")}>{children}</div>;
};

/**
 * Desktop footer component
 */
const DesktopFooter = (props: { year: number}) => (
  <div className="nxu-footer">
    <div className="nxu-footer__toolbar">
      <IonButtons slot="start">
        <a href={NEXFORD_PRIVACY_POLICY}>Privacy Policy</a>
      </IonButtons>
      <div className="nxu-footer__title">&copy; Nexford University {props.year}. All rights reserved.</div>
      <IonButtons slot="end">
        <a href={`mailto:${NEXFORD_MAIL_SUCCESS}`}>Contact Us</a>
      </IonButtons>
    </div>
  </div>
);

/**
 * Basic version of Page Wrap, not reliant on any providers, for use on anonymous pages eg Login
 */
export const AnonymousPageWrap = (props: { children: React.ReactNode }) => {
  const year = new Date().getFullYear();
  return (
    <IonPage data-testid="nxu-page-wrap" id="nxu-main-content">
      <IonHeader mode="md" className="nxu-page__header" data-testid="nxu-header">
        <IonToolbar className="header__titlebar">
          <IonTitle>
            <div className="header-logo-wrapper">
              <LogoImg aria-label="Nexford University myNXU" className="header-logo" />
            </div>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>{props.children}</IonContent>
      <DesktopFooter year={year} />
    </IonPage>
  );
};

/**
 * Page layout component inserting head/nav/layout to all standard page
 */
const PageWrap = (props: { children: React.ReactNode }) => {
  const { initialisingCampus, initialisedCampus, initialisedCampusError, readmissionRequired } =
    useInitialisationContext();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { instance } = useMsal();
  const year = new Date().getFullYear();

  const { children } = props;

  const [isNavHidden, setIsNavHidden] = useState<boolean>();

  useEffect(() => {
    if (initialisedCampusError || readmissionRequired || pathname === LocalRoutes.ENROLL) {
      if (!isNavHidden) setIsNavHidden(true);
    } else if (isNavHidden) setIsNavHidden(false);
  }, [setIsNavHidden, initialisedCampusError, isNavHidden, readmissionRequired, pathname]);

  const SignOutButton = () => (
    <IonItem
      data-testid="nxu-side-nav-logout"
      lines="full"
      color="danger"
      onClick={() => signOutClickHandler(instance)}
    >
      <IonIcon icon={logOutOutline}></IonIcon>
      <IonLabel>Logout</IonLabel>
    </IonItem>
  );

  const useSideMenu = !isMobileOS();
  const useTabBar = isMobileOS() && !isNavHidden;

  if (initialisingCampus || !initialisedCampus) {
    return <LoadingInterstitial />;
  }

  return (
    <>
      <IonMenu side="end" className="nxu-desktop-nav" disabled={!useSideMenu} contentId="nxu-main-content" swipeGesture={false}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>myNXU</IonTitle>
          </IonToolbar>
        </IonHeader>
        {!isNavHidden && (
          <IonContent>
            <IonList>
              {desktopMenuNavItems.map((item) => {
                const isActive = pathname.split("/")[1] === item.path.split("/")[1];

                return (
                  <IonMenuToggle key={`desktop-nav-${item.label}`}>
                    <IonItem
                      className={cx(isActive ? "nxu-desktop-nav--active" : "")}
                      lines="full"
                      detail
                      onClick={() => navigate(item.path)}
                    >
                      <IonIcon icon={item.icon}></IonIcon>
                      <IonLabel>{item.label}</IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                );
              })}
            </IonList>
          </IonContent>
        )}
        <IonFooter>
          <IonMenuToggle>
            <SignOutButton />
          </IonMenuToggle>
        </IonFooter>
      </IonMenu>

      <IonPage data-testid="nxu-page-wrap" id="nxu-main-content">
        <Header isNavHidden={isNavHidden} />

        {initialisedCampusError && (
          <IonContent>
            <div className="page-content">
              <NxuAlert message={initialisedCampusError} />
              <GetInTouch />
            </div>
            {!useTabBar && <DesktopFooter year={year} />}
          </IonContent>
        )}

        {!initialisedCampusError && (
          <IonContent>
            {children}
            {!useTabBar && <DesktopFooter year={year} />}
          </IonContent>
        )}

        {useTabBar && (
          <IonFooter className="nxu-mobile-nav">
            <nav>
              {mobileFooterNavItems.map((item) => {
                const isActive = pathname.split("/")[1] === item.path.split("/")[1];
                return (
                  <IonButton
                    key={`mobile-nav-${item.label}`}
                    className={cx(isActive ? "nxu-mobile-nav--active" : "")}
                    color="light"
                    fill="clear"
                    onClick={() => navigate(item.path)}
                  >
                    <div>
                      <IonIcon icon={item.icon} />
                      <IonLabel>{item.label}</IonLabel>
                    </div>
                  </IonButton>
                );
              })}
            </nav>
          </IonFooter>
        )}
      </IonPage>
    </>
  );
};

export default PageWrap;
