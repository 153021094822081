import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { IPublicClientApplication, InteractionRequiredAuthError } from "@azure/msal-browser";

import { appInsights } from "utils/app-insights-telemetry";

import msalConfig from "config";

export interface QueryErrorType {
  message: string;
  description: string;
  statusCode: string | number;
}

// Attempt to get the access token required for API requests
const tokenAcquisition = (msalInstance?: IPublicClientApplication) => {
  let accessToken = localStorage.getItem("msalToken");

  // If there's no token in storage, attempt to access it from the msal instance
  if (!accessToken) {
    if (!msalInstance) throw Error("No active account found");

    const accessTokenRequest = {
      scopes: [msalConfig.authentication.campusApiScope],
      account: msalInstance?.getAllAccounts()[0],
    };

    msalInstance
      .acquireTokenSilent(accessTokenRequest)
      .then((response) => {
        accessToken = response.accessToken;
        localStorage.setItem("msalToken", response.accessToken);
      })
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          msalInstance.acquireTokenRedirect(accessTokenRequest);
        }
      });
  }

  return accessToken;
};

// Default error messages to use when the request doesn't include a custom error message
const errorMsg = {
  genericGet: "Uh-oh! Please refresh and try again or contact support.",
  genericPost: "Uh-oh! Please refresh and try again or contact support.",
};

// Standardise the get request into a single function
export const apiGet = async (route: string, customErrorMessage?: string, msalInstance?: IPublicClientApplication) => {
  const accessToken = tokenAcquisition(msalInstance);
  let json;

  try {
    const request = await fetch(route, {
      method: "GET",
      headers: [["Authorization", `Bearer ${accessToken}`]],
    });
    if (!request.ok) throw await request;
    // If a request has no content, return an empty object
    if (request.status === 204) return {};
    try {
      json = await request.json();
    } catch {
      json = {};
    }
  } catch (e) {
    console.warn(e);
    appInsights.trackException({ exception: e as Error, severityLevel: SeverityLevel.Error });

    const error = e as Response;
    if (error.status === 401 && msalInstance) {
      // If error is a 401 and we have access to the msalInstance, attempt to trigger a redirect event
      return msalInstance.acquireTokenRedirect({ scopes: [msalConfig.authentication.campusApiScope] });
    }

    throw new Error(`${customErrorMessage || errorMsg.genericGet} Error: ${error.status} on fetch from ${route}`, {
      cause: error.status,
    });
  }

  return json;
};

// Standardise the post request into a single function
export const apiPost = async (
  route: string,
  payload: { [key: string]: any },
  customErrorMessage?: string,
  msalInstance?: IPublicClientApplication,
) => {
  const accessToken = tokenAcquisition(msalInstance);
  let json;

  try {
    const request = await fetch(route, {
      method: "post",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    if (!request.ok) throw await request;
    // If a request has no content, return an empty object
    if (request.status === 204) return {};

    try {
      json = await request.json();
    } catch {
      json = {};
    }
  } catch (e) {
    console.warn(e);
    appInsights.trackException({ exception: e as Error, severityLevel: SeverityLevel.Error });
    const error = e as Response;
    throw new Error(`${customErrorMessage || errorMsg.genericPost} Error: ${error.status} on post to ${route}`, {
      cause: error.status,
    });
  }

  return json;
};

// The not-standard post request for file upload
export const apiPostFile = async (
  route: string,
  file: File,
  customErrorMessage?: string,
  msalInstance?: IPublicClientApplication,
) => {
  const accessToken = tokenAcquisition(msalInstance);
  let json;

  try {
    const request = await fetch(route, {
      method: "post",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: file,
    });
    if (!request.ok) throw await request;
    // If a request has no content, return an empty object
    if (request.status === 204) return {};

    try {
      json = await request.json();
    } catch {
      json = {};
    }
  } catch (e) {
    console.warn(e);
    appInsights.trackException({ exception: e as Error, severityLevel: SeverityLevel.Error });
    const error = e as Response;
    throw new Error(`${customErrorMessage || errorMsg.genericPost} Error: ${error.status} on post to ${route}`, {
      cause: error.status,
    });
  }

  return json;
};
