import React, { useEffect, useState } from "react";
import cx from "classnames";
import { IonButton, IonFab, IonFabButton, IonIcon, IonPopover } from "@ionic/react";
import { checkmarkCircleOutline, warningOutline, cardOutline, informationCircleOutline } from "ionicons/icons";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";

import { NxuAlert, NxuComponentLoading } from "@nexford/nexford-ui-component-library";
import { PaymentGroup, PaymentGroups, TuitionInvoice } from "types/wallet";
import { ProgramEnrollmentStatus } from "types/learner";
import {formatDate, formatShortDate} from "utils/format-date.utils";
import { LocalRoutes } from "constants/routes";
import { NEXFORD_MAIL_BILLINGS } from "constants/external-routes";
import SecondaryButton from "components/atom/button-secondary/button-secondary";
import CardPanel from "components/atom/card-panel";
import FileDownloadButton from "components/atom/file-download-button";
import { useInvoiceLink, walletErrMsg } from "utils/hooks/wallet";

import { ReactComponent as RefundIcon } from "assets/img/refund.svg";

import "./billing-info.scss";

import TuitionCap from "../tuition-cap";

const PAID = "Paid";
const NOTPAID = "NotPaid";
const PARTIALLY_PAID = "PartiallyPaid";
const VOIDED = "Voided";

export interface BillingInfoProps {
  nextInvoiceDate?: string;
  paymentFrequency?: number;
  invoices: Array<TuitionInvoice>;
  totalAmountDue: number;
  balance: number;
  hasOldInvoices: boolean;
  programStatus: ProgramEnrollmentStatus;
  paymentGroup: PaymentGroup | undefined | null;
}

/**
 * Display the user's current balance and  history of the invoices
 */
const BillingInfo = (props: BillingInfoProps) => {
  const { instance } = useMsal();
  const navigate = useNavigate();

  const {
    nextInvoiceDate,
    paymentFrequency,
    invoices,
    totalAmountDue,
    balance,
    hasOldInvoices,
    paymentGroup,
    programStatus,
  } = props;

  const [newestUnpaidInvoice, setNewestUnpaidInvoice] = useState<number>();

  const [selectedInvoiceId, setSelectedInvoiceId] = useState<number>();
  const {
    data: invoiceLink,
    isLoading: invoiceLinkLoading,
    error: invoiceLinkError,
  } = useInvoiceLink(instance, selectedInvoiceId);

  useEffect(() => {
    if (selectedInvoiceId && invoiceLink) {
      window.open(invoiceLink.DownloadLink, "_self");
      setSelectedInvoiceId(undefined);
    }
  }, [selectedInvoiceId, invoiceLink]);

  const billingFreqString = `Tuition paid ${paymentFrequency === 1 ? "monthly" : `every ${paymentFrequency} months`}`;

  const earlyInvoiceDate = nextInvoiceDate ? new Date(nextInvoiceDate) : null;
  if (earlyInvoiceDate) {
    const timezoneOffset = new Date().getTimezoneOffset();
    earlyInvoiceDate.setHours(earlyInvoiceDate.getHours() - timezoneOffset / 60);
  }
  const nextInvoiceDateFormatted = earlyInvoiceDate ? formatDate(earlyInvoiceDate) : null;

  const isNegative = balance < 0;

  const relaxed = isNegative && paymentGroup?.adjustedBalance === 0;

  const minimunDueAmountToPay =
    isNegative && !!paymentGroup?.adjustedBalance ? paymentGroup.adjustedBalance : totalAmountDue;

  const amountFormatted = (amount: number) => (Number.isInteger(amount) ? amount : amount.toFixed(2));

  const warningMessage = (amount: number) => {
    switch (programStatus) {
      case ProgramEnrollmentStatus.Suspended:
        return `Pay $${amountFormatted(amount)} to remove your suspension and avoid being dismissed`;
      case ProgramEnrollmentStatus.Dismissed:
      case ProgramEnrollmentStatus.Withdrawn:
        return "Clear your outstanding balance to re-enroll at Nexford";
      case ProgramEnrollmentStatus.Completed:
        return "Clear your outstanding balance to graduate";
      default:
        break;
    }

    if (paymentGroup?.name === PaymentGroups.NGUnderPayment) {
      return `Pay at least $${amountFormatted(amount)} to avoid suspension`;
    }

    return "Clear your outstanding balance to avoid suspension";
  };

  useEffect(() => {
    if (newestUnpaidInvoice === undefined && invoices?.length) {
      const latestInvoice = invoices.find((item) => item.status === NOTPAID || item.status === PARTIALLY_PAID);
      setNewestUnpaidInvoice(latestInvoice?.invoiceId);
    }
  }, [invoices, newestUnpaidInvoice, setNewestUnpaidInvoice]);

  // Proceed to payment stage for a specific invoice
  const goToInvoicePayment = (amountSet?: number) => {
    if (amountSet) navigate(`${LocalRoutes.WALLET_PAYMENT}?amount=${amountSet}`);
    else navigate(`${LocalRoutes.WALLET_PAYMENT}?amount=full`);
  };

  // Proceed to payment stage
  const goToTopupPayment = () => {
    navigate(LocalRoutes.WALLET_PAYMENT);
  };

  return (
    <section className="billing-info__wrapper" data-testid="billing-info-wrapper">
      <CardPanel className="billing-info__next-payment">
        <div className="billing-info__next-payment__row">
          <div className="billing-info__next-payment__col">
            {!!paymentFrequency && (
              <div className="billing-info__next-payment__freq" data-testid="payment-frequency">
                {billingFreqString}
              </div>
            )}
          </div>

          {nextInvoiceDateFormatted && !totalAmountDue && (
            <div
              className="billing-info__next-payment__col  billing-info__next-payment__due"
              data-testid="next-invoice-date"
            >
              <span>Next invoice available on</span>
              <strong>{nextInvoiceDateFormatted}</strong>
            </div>
          )}
        </div>

        <div className="billing-info__next-payment__col billing-info__next-payment__row">
          <div className="billing-info__next-payment">
            <span className="billing-info__next-payment__title">
              <h6>Your Balance</h6>
              {relaxed && (
                <>
                  <IonButton id="relaxed-balance-tooltip-trigger" shape="round" size="small">
                    <IonIcon slot="icon-only" icon={informationCircleOutline}></IonIcon>
                  </IonButton>
                  <IonPopover
                    className="relaxed-balance-tooltip-trigger"
                    trigger="relaxed-balance-tooltip-trigger"
                    triggerAction="click"
                    side="bottom"
                    alignment="start"
                  >
                    <p>Relaxed financial suspension rules mean you are not currently at risk of suspension</p>
                  </IonPopover>
                </>
              )}
            </span>
            {isNegative ? (
              <div className="billing-info__next-payment__amount red" data-testid="balance-due">
                -${amountFormatted(totalAmountDue)}
              </div>
            ) : (
              <div className="billing-info__next-payment__amount" data-testid="wallet-balance">
                ${amountFormatted(balance)}
              </div>
            )}
            {isNegative && !relaxed && (
              <div className="billing-info__next-payment__warning">{warningMessage(minimunDueAmountToPay)}</div>
            )}
          </div>
          {!totalAmountDue ? (
            <div className="billing-info__next-payment__col">
              <SecondaryButton ariaText="Add funds to your wallet" size="default" onClick={goToTopupPayment}>
                Top up
              </SecondaryButton>
              <IonButton
                id="wallet-funds-tooltip-trigger"
                aria-label="Open wallet funds tooltip"
                role="button"
                shape="round"
                size="small"
              >
                <IonIcon slot="icon-only" icon={informationCircleOutline}></IonIcon>
              </IonButton>
              <IonPopover
                className="wallet-funds-tooltip"
                trigger="wallet-funds-tooltip-trigger"
                triggerAction="click"
                side="bottom"
                alignment="start"
              >
                <p>Funds in your Wallet will automatically apply to future invoices.</p>
              </IonPopover>
            </div>
          ) : (
            <div className="billing-info__next-payment__col billing-info__next-payment__col-cta">
              <SecondaryButton onClick={() => goToInvoicePayment()}>Pay now</SecondaryButton>
            </div>
          )}
        </div>
      </CardPanel>

      <TuitionCap />

      <CardPanel className="billing-info__invoice-history">
        <h3>Account History</h3>
        <div className="billing-info__invoices-list">
          {invoices?.map((invoice: TuitionInvoice, i: number) => {
            const { status, amountDue } = invoice;
            const isStatusPaid = status === PAID;
            const dueDate = new Date(invoice.dueDate);
            dueDate.setDate(dueDate.getDate() + 1);
            const datePaidFormat = invoice.datePaid ? formatDate(invoice.datePaid) : "";

            let invoiceTitle = invoice.paymentPurpose;
            if (invoice.productFriendlyName) {
              invoiceTitle = `${formatShortDate(invoice.period)} ${invoice.productFriendlyName}`;
              invoiceTitle += invoice.isFoundation ? " Fee" : " Tuition";
            }

            return (
              <div key={`invoice-${i + 1}`}>
                <div className="invoice-item" data-testid="invoice-item">
                  <div className="invoice-item__col">
                    <div className="invoice-item__name" data-testid="invoice-item-name">
                      {invoiceTitle}
                    </div>
                    <div className={cx(!isStatusPaid && "invoice-item__col--unpaid")}>
                      <div className="invoice-item__status">
                        {isStatusPaid ? (
                          <IonIcon icon={checkmarkCircleOutline} color="primary" />
                        ) : (
                          <IonIcon icon={warningOutline} color="danger" />
                        )}
                        <span>
                          {status === PAID && `Paid on ${datePaidFormat}`}
                          {(status === PARTIALLY_PAID || status === NOTPAID) &&
                            `$${amountFormatted(amountDue)} still to pay`}
                          {status === VOIDED && "Invoice voided"}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="invoice-item__col invoice-item__col--action">
                    {!!invoice.refundedCredit && (
                      <>
                        <IonButton
                          id={`refund-${invoice.invoiceId}-tooltip-trigger`}
                          className="invoice-refund-tooltip-trigger"
                        >
                          <RefundIcon aria-label="Check refunded credit" />
                        </IonButton>
                        <IonPopover
                          className="invoice-refund-tooltip"
                          trigger={`refund-${invoice.invoiceId}-tooltip-trigger`}
                          triggerAction="click"
                          side="bottom"
                          alignment="start"
                        >
                          <p>${invoice.refundedCredit} refunded</p>
                        </IonPopover>
                      </>
                    )}

                    <div className="invoice-item__download-cta">
                      {(!invoiceLinkLoading || (invoiceLinkLoading && selectedInvoiceId !== invoice.invoiceId)) && (
                        <FileDownloadButton
                          ariaLabel={`Download invoice for ${invoice.paymentPurpose}`}
                          downloadEvent={() => setSelectedInvoiceId(invoice.invoiceId)}
                        />
                      )}
                      {invoiceLinkLoading && selectedInvoiceId === invoice.invoiceId && <NxuComponentLoading />}
                    </div>
                    <div className={cx("invoice-item__amount", status === "voided" && "invoice-item__amount--voided")}>
                      $
                      {isStatusPaid
                        ? amountFormatted(invoice.amountPaid)
                        : amountFormatted(invoice.actualAmount - invoice.discountApplied)}
                    </div>
                  </div>
                </div>
                {invoiceLinkError && selectedInvoiceId === invoice.invoiceId && (
                  <NxuAlert message={invoiceLinkError.message || walletErrMsg.downloadInvoice} />
                )}
              </div>
            );
          })}
        </div>
        {hasOldInvoices && (
          <div>
            <p className="billing-info__invoice-help">
              No history available before January 2023. For queries on older invoices, please contact{" "}
              <a href={`mailTo:${NEXFORD_MAIL_BILLINGS}`} target="_blank">
                {NEXFORD_MAIL_BILLINGS}
              </a>
              .
            </p>
          </div>
        )}
      </CardPanel>
      {!!totalAmountDue && (
        <div className="billing-info__payment-fab--sticky">
          <IonFab className="billing-info__payment-fab" data-testid="payment-fab">
            <IonFabButton color="secondary" onClick={() => goToInvoicePayment()}>
              <IonIcon icon={cardOutline} aria-label="Pay now" />
            </IonFabButton>
          </IonFab>
        </div>
      )}
    </section>
  );
};

export default BillingInfo;
