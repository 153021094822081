import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import cx from "classnames";

import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import { LocalRoutes } from "constants/routes";
import { NxuComponentLoading, NxuPrimaryButton, NxuCardPanel } from "@nexford/nexford-ui-component-library";
import { useAuthContext } from "utils/context/Auth";
import { useLearner, useProgramProgress } from "utils/hooks/learners";

import "./program-summary-card.scss";

/**
 * Display the top-level details for the user's program
 */
const ProgramSummaryCard = () => {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const { userAccount } = useAuthContext();
  const { data: aggregateLearnerProfile } = useLearner(!!userAccount, instance);
  const {
    data: programProgress,
    isLoading: loadingProgramProgress,
    isError: programProgressError,
  } = useProgramProgress(!!userAccount, instance);

  const displayGpa =
    aggregateLearnerProfile &&
    aggregateLearnerProfile.productType !== "Course" &&
    programProgress &&
    programProgress.gpa !== null;

  const [gpaPrime, setGpaPrime] = useState<string>("0");
  const [gpaDecimal, setGpaDecimal] = useState<string>("00");

  useEffect(() => {
    if (programProgress?.gpa) {
      const gpaParticles = programProgress.gpa.split(".");
      setGpaPrime(gpaParticles[0] || "0");
      setGpaDecimal(gpaParticles[1] || "00");
    }
  }, [programProgress]);

  const GraphAndList: React.FunctionComponent = () => {
    if (programProgress) {
      return (
        <div className="program-summary-card__columns">
          <div className="program-summary-card__left program-summary-card__graph">
            <CircularProgressbarWithChildren
              styles={buildStyles({ pathColor: `#098b5b`, textColor: "#098b5b" })}
              strokeWidth={9}
              value={programProgress.progress || 0}
            >
              <div className="program-summary-card__graph-details">
                <span>{programProgress.progress || 0}%</span> <span>complete</span>
              </div>
            </CircularProgressbarWithChildren>
          </div>

          <div className="program-summary-card__right program-summary-card__list">
            <div className="program-summary-card__list-inner">
              {!!programProgress.currentCourses?.length && (
                <>
                  <h3>{programProgress.currentCourses?.length > 1 ? "Current Courses" : "Current Course"}</h3>
                  <div className="courses-list" data-testid="courses-list">
                    {programProgress.currentCourses.map((item) => (
                      <span key={`${item}-key`} className="courses-list__item">
                        {item}
                      </span>
                    ))}
                  </div>
                </>
              )}
              {!programProgress.currentCourses?.length && (
                <p className="program-summary-card__empty">You currently have no courses in progress</p>
              )}
              <NxuPrimaryButton onClick={() => navigate(LocalRoutes.ACADEMICS)}>View my Learning Path</NxuPrimaryButton>
            </div>
          </div>
        </div>
      );
    }

    return <></>;
  };

  if (loadingProgramProgress) {
    return (
      <NxuCardPanel>
        <NxuComponentLoading />
      </NxuCardPanel>
    );
  }
  return (
    <NxuCardPanel
      className={cx("program-summary-card", displayGpa && "program-summary-card--gpa")}
      testId="program-summary-card"
    >
      {displayGpa && (
        <div className="program-summary-card__gpa-icon" data-testid="program-summary-card__gpa">
          <div>
            {!programProgress?.gpa && <div className="program-summary-card__gpa-value">--</div>}
            {programProgress.gpa && (
              <div className="program-summary-card__gpa-value">
                <span>{gpaPrime}</span>
                <span>.{gpaDecimal}</span>
              </div>
            )}
            <span className="program-summary-card__gpa-sub">GPA</span>
          </div>
        </div>
      )}
      <div className="program-summary-card__title-row">
        <h2>{programProgress?.productFriendlyName || aggregateLearnerProfile?.programName}</h2>
        {!!aggregateLearnerProfile?.specializations?.length && (
          <p>
            {aggregateLearnerProfile.specializations.length > 1 ? "Specializations:" : "Specialization:"}
            {aggregateLearnerProfile.specializations.map((item) => (
              <span>{item}</span>
            ))}
          </p>
        )}
      </div>
      {programProgressError && (
        <p className="program-summary-card__empty">
          We're having difficulty getting your program summary. Please refresh the page and if the problem persists,
          contact support for help
        </p>
      )}
      {!!programProgress && <GraphAndList />}
    </NxuCardPanel>
  );
};
export default ProgramSummaryCard;
