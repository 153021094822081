export enum ProgramProviderOptions {
  NEXFORD = "Nexford",
  COURSERA = "Coursera",
  THINKFUL = "Thinkful",
}

export type ProgramProviderTypes =
  | typeof ProgramProviderOptions.NEXFORD
  | typeof ProgramProviderOptions.COURSERA
  | typeof ProgramProviderOptions.THINKFUL;

export enum ProgramEnrollmentStatus {
  Enrolled = "enrolled",
  Completed = "completed",
  Withdrawn = "withdrawn",
  Suspended = "suspended",
  Dismissed = "dismissed",
}

export type ProgramEnrollmentStatusTypes =
  | typeof ProgramEnrollmentStatus.Enrolled
  | typeof ProgramEnrollmentStatus.Completed
  | typeof ProgramEnrollmentStatus.Suspended
  | typeof ProgramEnrollmentStatus.Dismissed
  | typeof ProgramEnrollmentStatus.Withdrawn;

export interface AggregateLearnerProfile {
  firstName: string;
  lastName: string;
  learnerId: string;
  countryOfResidence: string;
  email: string;
  phoneNumber?: string;
  productCode: string;
  productType: string;
  provider: ProgramProviderTypes;
  programName: string;
  programAcademicName: string;
  programFriendlyName: string;
  programDescription: string;
  programStatus: ProgramEnrollmentStatusTypes;
  programEnrollmentId: string;
  programStartDate: string;
  specializations?: string[];
  unsignedAgreementPending: boolean;
}

export interface ProgramProgressSummary {
  currentCourses: Array<string>;
  productFriendlyName: string;
  progress: number;
  gpa: string | null;
}

export enum DocumentStatusTypeOptions {
  id = "id",
  photo = "photo",
}

export interface AepDocument {
  name: string;
  status: AepDocumentStatus;
  isDefault: boolean;
}

export enum AepDocumentStatusOptions {
  ok = "ok",
  incorrect = "incorrect",
  missing = "missing",
  unreadable = "unreadable",
}

export enum CommonAepDocument {
  EnglishCertificate = "English certificate",
  GradesTranscript = "Grades transcript",
  Affidavit = "Affidavit",
  AdditionalId = "Additional id",
  CopyOfDegreeCertificate = "Copy of Degree Certificate",
}

export type AepDocumentStatus =
  | typeof AepDocumentStatusOptions.ok
  | typeof AepDocumentStatusOptions.incorrect
  | AepDocumentStatusOptions.missing
  | AepDocumentStatusOptions.unreadable;

export type DocumentStatusTypes = typeof DocumentStatusTypeOptions.id | typeof DocumentStatusTypeOptions.photo | string;

export enum DocumentStatusOptions {
  passed = "Passed",
  failed = "Failed",
  notReviewed = "NotReviewed",
  notUploaded = "NotUploaded",
}

export type DocumentStatus =
  | typeof DocumentStatusOptions.passed
  | typeof DocumentStatusOptions.failed
  | DocumentStatusOptions.notReviewed
  | DocumentStatusOptions.notUploaded;

export interface DocumentStatusItem {
  comment: string;
  documentId: DocumentStatusTypes;
  learnerId: string;
  status: DocumentStatus;
}
export interface DocumentStatusList {
  documents: Array<DocumentStatusItem>;
}
