export const LocalRoutes = {
  HOME: "/",

  ENROLL: "/enroll",
  ENROLL_COMPLETION: "/enroll/completion",

  WALLET: "/wallet",
  WALLET_PAYMENT: "/wallet/payment",

  ACADEMICS: "/program",

  PROFILE: "/profile",
  DOCUMENTS: "/profile/documents",

  LOGIN: "/login",
  LOGOUT: "/logout",
  MAINTENANCE: "/maintenance",
};
