import React, { useEffect, useState } from "react";

import { IonButton, IonButtons, IonModal, IonHeader, IonToolbar, IonTitle, IonContent, IonFooter } from "@ionic/react";

import { NEXFORD_MAIL_BILLINGS, PAYPAL_URL } from "constants/external-routes";
import { PPO } from "types/wallet";

import { NxuPrimaryButton } from "@nexford/nexford-ui-component-library";
import GetInTouch from "components/atom/get-in-touch";

import "./provider-modal.scss";

const { PAYPAL, TRANSFER } = PPO;

export interface ProviderModalProps {
  type: string;
  isOpen: boolean;
  closeModal: () => void;
  learnerId: string;
}

/**
 * Display an info modal for payment methods that re-direct the user off Campus
 */
const ProviderModal = (props: ProviderModalProps) => {
  const { isOpen, closeModal, type, learnerId } = props;

  const [modalTitle, setModalTitle] = useState<string>();
  const [paymentComplete, setPaymentComplete] = useState(false);

  const isPaypalPay = type === PAYPAL;
  const isTransferPay = type === TRANSFER;

  useEffect(() => {
    if (type === PAYPAL) setModalTitle("Pay with PayPal");
    if (type === TRANSFER) setModalTitle("Pay with Bank Transfer");
  }, [setModalTitle, type]);

  const handleClose = () => {
    closeModal();
    setPaymentComplete(false);
  };

  const PaypalContent = () => {
    if (paymentComplete) {
      return (
        <div className="provider-modal__content-inner provider-modal__content--paypal">
          <p>
            Once you have completed your transaction, it will take up to 48 hours to be verified. Send a screenshot of
            your transaction and transaction hash/ID to{" "}
            <a href={`mailTo:${NEXFORD_MAIL_BILLINGS}`} target="_blank">
              {NEXFORD_MAIL_BILLINGS}
            </a>{" "}
            to process your payment faster. When verified, your Nexford Advisor will contact you confirming your
            enrollment.
          </p>
        </div>
      );
    }

    return (
      <div className="provider-modal__content-inner provider-modal__content--paypal">
        <p>Go to PayPal to complete your transaction. You will need to enter the:</p>
        <ul>
          <li>Correct payment amount</li>
          <li>Your learner ID: {learnerId}</li>
          <li>What you are paying for (i.e. MBA Tuition 1x month) in the 'note' section during checkout</li>
        </ul>
      </div>
    );
  };

  const TransferContent = () => (
    <div className="provider-modal__content-inner provider-modal__content--transfer">
      <p>You can pay your application or tuition fees using a Bank Wire transfer:</p>
      <ul>
        <li data-testid="transfer-modal-account-name">
          <b>Account name:</b> Nexford University
        </li>
        <li data-testid="transfer-modal-account-number">
          <b>Account number:</b> 226001335778
        </li>
        <li data-testid="transfer-modal-routing-number">
          <b>Routing number:</b> 026009593
        </li>
        <li data-testid="transfer-modal-swift-code">
          <b>SWIFT:</b> BOFAUS3N
        </li>
        <li data-testid="transfer-modal-bank-address">
          <b>Bank Address: </b>Bank of America, 222 Broadway, New York, New York 10038
        </li>
        <li data-testid="transfer-modal-learnerId">
          <b>Reference: </b> {learnerId} <em>(your unique Learner ID)</em>
        </li>
      </ul>
      <p>
        After making payment via bank wire transfer, please share a screenshot of your transaction, and transaction
        hash/ID to{" "}
        <a href={`mailTo:${NEXFORD_MAIL_BILLINGS}`} target="_blank">
          {NEXFORD_MAIL_BILLINGS}
        </a>{" "}
        from the same email address used in your Nexford application.
      </p>
    </div>
  );

  return (
    <IonModal
      data-testid="provider-modal"
      isOpen={isOpen}
      onDidDismiss={handleClose}
      className="nxu-modal provider-modal"
    >
      <IonHeader className="provider-modal__header">
        <IonToolbar>
          <IonTitle data-testid="provider-modal-title">{modalTitle}</IonTitle>
          <IonButtons slot="end">
            <IonButton fill="outline" onClick={handleClose}>
              Close
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="provider-modal__content">
        {isPaypalPay && <PaypalContent />}
        {isTransferPay && <TransferContent />}
        <div className="provider-modal__content-inner">
          <GetInTouch type="billings" />
        </div>
      </IonContent>

      <IonFooter className="provider-modal__footer">
        <IonToolbar>
          {!paymentComplete && isPaypalPay && (
            <NxuPrimaryButton onClick={() => setPaymentComplete(true)} href={PAYPAL_URL} target="_blank">
              Pay with PayPal
            </NxuPrimaryButton>
          )}
        </IonToolbar>
      </IonFooter>
    </IonModal>
  );
};

export default ProviderModal;
