import React, { useEffect } from "react";
import "@stripe/stripe-js";
import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { IonApp, setupIonicReact, isPlatform } from "@ionic/react";
import "@ionic/react/css/core.css";
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { MsalProvider, MsalAuthenticationTemplate, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { InteractionType, InteractionStatus, IPublicClientApplication } from "@azure/msal-browser";

import { hotjar } from "react-hotjar";

import { StripeProvider } from "utils/context/Stripe";
import { AuthProvider, anonymousPages, anonymousPagesToRedirect } from "utils/context/Auth";
import { InitialisationProvider } from "utils/context/Initialisation";
import { aiReactPlugin } from "utils/app-insights-telemetry";
import { LocalRoutes } from "constants/routes";

import Dashboard from "components/page/campus-landing-page/dashboard";
import AgreementPage from "components/page/agreement-page";
import SuccessPage from "components/page/success-page";
import WalletPage from "components/page/wallet-page";
import AcademicsPage from "components/page/academics-page";
import ErrorPage, { MaintenancePage } from "components/page/error-page";
import Login, { LogoutPage } from "components/page/login-page";
import ProfilePage from "components/page/profile-page";

import PageWrap from "components/molecule/page-wrap";
import { AnonymousPageWrap } from "components/molecule/page-wrap/page-wrap";
import LoadingInterstitial from "components/molecule/loading-interstitial";
import AuthErrored from "components/atom/auth-errored";

import config from "config";

import "./app.scss";
import PaymentPage from "../components/page/payment-page";

/**
 * Global config for the Ionic Interactions
 */
setupIonicReact({
  // Disable animations on desktop
  animated: !isPlatform("desktop"),
  mode: "md",
});

/**
 * Routing, page layouts and Provider declarations
 */
const CampusPages = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  // ToDo: look for alternatives to useIsAuth,
  // Still returns true when an account exists but the token has expired
  const isAuthenticated = useIsAuthenticated();
  const { inProgress } = useMsal();

  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      const redirectRoute = localStorage.getItem("afterAuthRedirect");
      if (!anonymousPages.includes(pathname) && !isAuthenticated) {
        localStorage.setItem("afterAuthRedirect", pathname);
        navigate(LocalRoutes.LOGIN);
      } else if (anonymousPagesToRedirect.includes(pathname) && isAuthenticated) {
        if (redirectRoute) {
          localStorage.removeItem("afterAuthRedirect");
          navigate(redirectRoute);
        } else {
          navigate(LocalRoutes.HOME);
        }
      }
    }
  }, [inProgress, isAuthenticated, navigate, pathname]);

  // Don't attempt a page render while MSAL is still in startup
  if (inProgress === InteractionStatus.Startup || inProgress === InteractionStatus.HandleRedirect) {
    return <></>;
  }

  if (anonymousPages.includes(pathname)) {
    return (
      <AnonymousPageWrap>
        <Routes>
          <Route path={LocalRoutes.MAINTENANCE} element={<MaintenancePage />} />
          <Route path={LocalRoutes.LOGIN} element={<Login />} />
          <Route path={LocalRoutes.LOGOUT} element={<LogoutPage />} />
        </Routes>
      </AnonymousPageWrap>
    );
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Silent}
      authenticationRequest={{ scopes: ["User.Read"] }}
      errorComponent={AuthErrored}
      loadingComponent={LoadingInterstitial}
    >
      <AuthProvider>
        <StripeProvider>
          <InitialisationProvider>
            <PageWrap>
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path={LocalRoutes.ENROLL} element={<AgreementPage />} />
                <Route path={LocalRoutes.ENROLL_COMPLETION} element={<SuccessPage />} />
                <Route path={LocalRoutes.WALLET} element={<WalletPage />} />
                <Route path={LocalRoutes.WALLET_PAYMENT} element={<PaymentPage />} />
                <Route path={LocalRoutes.ACADEMICS} element={<AcademicsPage />} />
                <Route path={LocalRoutes.PROFILE} element={<ProfilePage />} />
                <Route path={LocalRoutes.DOCUMENTS} element={<ProfilePage />} />
                <Route path="*" element={<ErrorPage />} />
              </Routes>
            </PageWrap>
          </InitialisationProvider>
        </StripeProvider>
      </AuthProvider>
    </MsalAuthenticationTemplate>
  );
};

const queryClient = new QueryClient();

/**
 * GTM declaration
 */
const gtmService = (gtmID: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ "gtm.start": new Date().getTime(), event: "gtm.js" });

  const script = document.createElement("script");
  script.src = `https://www.googletagmanager.com/gtm.js?id=${gtmID}`;
  script.async = true;
  script.onerror = (error) => console.warn(`Unable to load gtm: ${error}`);
  document.head.appendChild(script);
};

interface AppProps {
  pca: IPublicClientApplication;
}

/**
 * Top level wrapper initialising external providers and themes
 */
const App = ({ pca }: AppProps) => {
  const { hotJar, gtm } = config;
  gtmService(gtm.id);
  hotjar.initialize({ id: Number(hotJar.id), sv: Number(hotJar.version) });

  return (
    <MsalProvider instance={pca}>
      <AppInsightsContext.Provider value={aiReactPlugin}>
        <QueryClientProvider client={queryClient}>
          <IonApp>
            <Router>
              <CampusPages />
            </Router>
          </IonApp>
        </QueryClientProvider>
      </AppInsightsContext.Provider>
    </MsalProvider>
  );
};

export default App;
